import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getPortalCallQualityData } from 'redux-toolkit/reducer/portalCallQualityReducer';

export default function useHealthCheckLogHook({ message }) {
    const dispatch = useDispatch();

    useEffect(() => {
        const command = message?.data?.message || '';
        const commandData = message?.data?.data || {};
        if (command === 'fetch-portal-call-quality-data') {
            dispatch(
                getPortalCallQualityData({
                    orgId: commandData?.org_id,
                    portalId: commandData?.portal_id,
                }),
            );
        }
    }, [message, dispatch]);
}
