import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getActivityListByPortalIdAPI } from '../../api/getActivityListByPortalIdAPI';
import { saveActivityListByPortalIdAPI } from 'api/saveActivityListByPortalIdAPI';
import { getActivityListByOrgIdAPI } from 'api/getActivityListByOrgIdAPI';

const initialState = {
    loading: false,
    error: false,
    errorObj: {} as any,
    activityList: {} as any,
    selectedAnalystics: 0 as any,
};

export const getActivityListByPortalId = createAsyncThunk(
    'portalActivity/getActivityListByPortalId',
    async (data: any, { rejectWithValue }) => {
        try {
            return await getActivityListByPortalIdAPI(data);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

// Fetch Activity Logs Based On The Organization.
export const getActivityListByOrgId = createAsyncThunk(
    'orgActivity/getActivityListByOrgId',
    async (data: any, { rejectWithValue }) => {
        try {
            return await getActivityListByOrgIdAPI(data);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

export const saveActivityListByPortalId = createAsyncThunk(
    'portalActivity/saveActivityListByPortalId',
    async (activity: any, { rejectWithValue }) => {
        try {
            return await saveActivityListByPortalIdAPI(activity);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

const portalActivitySlice = createSlice({
    name: 'portalActivity',
    initialState,
    reducers: {
        setActivityEmpty: (state) => {
            state.activityList = {} as any;
        },
        setSelectedAnalystics: (state, action) => {
            state.selectedAnalystics = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getActivityListByPortalId.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getActivityListByPortalId.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                if (
                    action.payload.hasOwnProperty('data') &&
                    action.payload.data &&
                    action.payload.data.hasOwnProperty('data') &&
                    action.payload.data.data &&
                    action.payload.data.hasOwnProperty('pager') &&
                    action.payload.data.pager
                ) {
                    state.activityList = { data: action.payload.data.data, pager: action.payload.data.pager };
                } else {
                    state.activityList = { data: action.payload.data, pager: {} };
                }
                state.errorObj = {};
            })
            .addCase(getActivityListByPortalId.rejected, (state, action: any) => {
                state.loading = false;
                state.error = true;
                state.errorObj = { message: action?.payload?.message };
                state.activityList = {};
            })
            // Activity Logs Based On Organization.
            .addCase(getActivityListByOrgId.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getActivityListByOrgId.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                if (
                    action.payload.hasOwnProperty('data') &&
                    action.payload.data &&
                    action.payload.data.hasOwnProperty('data') &&
                    action.payload.data.data &&
                    action.payload.data.hasOwnProperty('pager') &&
                    action.payload.data.pager
                ) {
                    state.activityList = { data: action.payload.data.data, pager: action.payload.data.pager };
                } else {
                    state.activityList = { data: action.payload.data, pager: {} };
                }
                state.errorObj = {};
            })
            .addCase(getActivityListByOrgId.rejected, (state, action: any) => {
                state.loading = false;
                state.error = true;
                state.errorObj = { message: action?.payload?.message };
                state.activityList = {};
            });
    },
});
export const { setActivityEmpty, setSelectedAnalystics } = portalActivitySlice.actions;
export default portalActivitySlice.reducer;
