import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import _ from 'lodash';
import { setPortalList, setPortal } from 'redux-toolkit/reducer/portalReducer';
import { PORTAL_STATES } from 'utils';
import { useAblyClientContext } from '../Provider/AblyClientProvider';
import { getPortalSatus, statusTextMapping } from 'utils/Utils';
import { statusOrder } from 'utils/constants';
import {
    manipulateIODeviceList,
    setMultiCameraConfig,
    setPortalIoDeviceList,
} from 'redux-toolkit/reducer/ioConfigReducer';

function getPortalStatus(portal) {
    return portal?.portalConnectionStatus;
}

const sortByStatus = (a, b) => {
    const statusA = getPortalStatus(a);
    const statusB = getPortalStatus(b);
    return statusOrder[statusA] - statusOrder[statusB];
};

export default function useAblyPresenceHook() {
    const dispatch = useDispatch();
    const { isAblyFeatureFlagEnabled } = useAblyClientContext();

    const { purePortalList, currentPortal, presenceData, ioDeviceList, listLoading, multiCameraConfig } = useSelector(
        ({ portal, ably, ioConfig }) => ({
            purePortalList: portal?.allPortalsData?.data,
            currentPortal: portal?.portal,
            presenceData: ably?.presenceData,
            ioDeviceList: ioConfig.ioDeviceList,
            listLoading: portal?.listLoading,
            multiCameraConfig: portal?.multiCameraConfig,
        }),
        _.isEqual,
    );

    const createData = (portal, presencePortalStatus) => {
        /**
         *  if db has PORTAL_STATES.ONLINE, PORTAL_STATES.BOOKED, PORTAL_STATES.IN_CALL this means it is incorrect state
         *  so we need to show offline status otherwise get default state based on getPortalSatus
         */
        const onlineOrBookedOrInCall = [PORTAL_STATES.ONLINE, PORTAL_STATES.BOOKED, PORTAL_STATES.IN_CALL];
        const defaultStatus = onlineOrBookedOrInCall.includes(portal?.portalConnectionStatus)
            ? PORTAL_STATES.OFFLINE
            : getPortalSatus(portal);

        const defaultStatusText = onlineOrBookedOrInCall.includes(portal?.portalConnectionStatus)
            ? statusTextMapping[PORTAL_STATES.OFFLINE]
            : statusTextMapping[getPortalSatus(portal)];

        return {
            ...portal,
            portalConnectionStatus: presencePortalStatus || defaultStatus,
            portalConnectionStatusText: presencePortalStatus
                ? statusTextMapping[presencePortalStatus]
                : defaultStatusText,
        };
    };

    useEffect(() => {
        if (currentPortal?.portal_id && isAblyFeatureFlagEnabled) {
            const presencePortal = presenceData.find((_portal) => _portal?.clientId === currentPortal?.portal_id);
            const presencePortalStatus = presencePortal?.data?.data?.status || presencePortal?.data?.status;
            if (currentPortal.hasOwnProperty('portalConnectionStatus')) {
                if (!currentPortal.isArchived) {
                    const data = createData(currentPortal, presencePortalStatus);
                    dispatch(setPortal(data));
                }
            }
            if (!_.isEqual(ioDeviceList, presencePortal?.data?.ioDevices)) {
                if (!presencePortal && !ioDeviceList?.message) {
                    dispatch(
                        setPortalIoDeviceList({
                            message: 'This portal is offline. Try again when the portal is online.',
                        }),
                    );
                } else if (
                    presencePortal &&
                    presencePortal?.data &&
                    presencePortal?.data.ioDevices &&
                    Object.keys(presencePortal?.data?.ioDevices).length > 0
                ) {
                    if (!_.isEqual(ioDeviceList, manipulateIODeviceList(presencePortal?.data?.ioDevices))) {
                        dispatch(setPortalIoDeviceList(presencePortal?.data?.ioDevices));
                    }
                    if (!_.isEqual(multiCameraConfig, presencePortal?.data?.multiCameraDevices)) {
                        dispatch(setMultiCameraConfig(presencePortal?.data?.multiCameraDevices || {}));
                    }
                }
            }
        }
    }, [currentPortal, presenceData, dispatch, isAblyFeatureFlagEnabled, ioDeviceList, multiCameraConfig]);

    useEffect(() => {
        if (purePortalList && isAblyFeatureFlagEnabled) {
            let portalList = purePortalList.reduce((acc, portal) => {
                const presencePortal = presenceData.find((_portal) => _portal?.clientId === portal.portal_id);
                const presencePortalStatus = presencePortal?.data?.data?.status || presencePortal?.data?.status;
                if (portal.hasOwnProperty('portalConnectionStatus')) {
                    const data = createData(portal, presencePortalStatus);
                    acc.push(data);
                }

                return acc;
            }, []);
            portalList = portalList.sort(sortByStatus);
            dispatch(setPortalList(portalList));
        }
    }, [purePortalList, presenceData, dispatch, isAblyFeatureFlagEnabled, listLoading]);

    return { presenceData };
}
