import { PORTAL_STATES } from './Utils';

export const TENANT_ADMIN = 'SuperAdmin';
export const ORG_ADMIN = 'OrgAdmin';
// added configs for video encoding
export const LOW_BIT_RATE = '100kbps';
export const LOW_SCALE_RESOLUTION_DOWN_BY = 6;
export const LOW_MAX_FRAME_RATE = 15;
export const MEDIUM_BIT_RATE = '500kbps';
export const MEDIUM_SCALE_RESOLUTION_DOWN_BY = 1.5;
export const MEDIUM_MAX_FRAME_RATE = 20;
export const MAXIMUM_BIT_RATE = '5mbps';
export const MAXIMUM_SCALE_RESOLUTION_DOWN_BY = 1;
export const MAXIMUM_MAX_FRAME_RATE = 30;

export const PRIV_ADMIN_USER_MGMT = 'admin-user-management';
export const PRIV_ADMIN_APP = 'admin-app';

// Portal states sorting order
export const statusOrder: { [key: string]: number } = {
    [PORTAL_STATES.ONLINE]: 1,
    [PORTAL_STATES.IN_CALL]: 2,
    [PORTAL_STATES.BOOKED]: 3,
    [PORTAL_STATES.IN_MAINTENANCE]: 4,
    [PORTAL_STATES.DISCONNECTED]: 5,
    [PORTAL_STATES.OFFLINE]: 6,
    [PORTAL_STATES.PENDING]: 7,
    [PORTAL_STATES.ARCHIVED]: 8,
    [PORTAL_STATES.UNREGISTERED]: 9,
    [PORTAL_STATES.ERROR]: 0,
};
