import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPortalCallQualityDataAPI } from 'api/getPortalCallQualityDataAPI';

const initialState = {
    loading: false,
    error: false,
    errorObj: {} as any,
    portalQualityData: {} as any,
};

export const getPortalCallQualityData = createAsyncThunk(
    'getHealthLogs/getPortalCallQualityData',
    async (data: any, { rejectWithValue }) => {
        try {
            return await getPortalCallQualityDataAPI(data);
        } catch (err: any) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    },
);

const portalCallQualitySlice = createSlice({
    name: 'healthLogs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPortalCallQualityData.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getPortalCallQualityData.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;

                if (
                    action.payload.hasOwnProperty('data') &&
                    action.payload.data &&
                    action.payload.data.hasOwnProperty('data') &&
                    action.payload.data.data
                ) {
                    state.portalQualityData = { data: action.payload.data.data };
                } else {
                    state.portalQualityData = { data: action.payload.data };
                }

                state.errorObj = {};
            })
            .addCase(getPortalCallQualityData.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.portalQualityData = {};
            });
    },
});

export default portalCallQualitySlice.reducer;
