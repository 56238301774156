import { apiGet } from './axios';
import { ENDPOINT_DEV } from './configs';

export const getChartDataAPI = async ({
    orgId,
    portalId,
    activeTab,
    type,
    startDate,
    endDate,
    timezone,
}: {
    orgId: string;
    portalId: string;
    activeTab: string;
    type: string;
    startDate?: Date;
    endDate?: Date;
    timezone?: string;
}) => {
    let url = `${ENDPOINT_DEV}analytics/${orgId}/portal/${portalId}/${type}/${activeTab}`;

    // Initialize query parameters array
    const queryParams = [];

    if (type === 'custom' && startDate) {
        queryParams.push(`startDate=${startDate.getTime()}`);
    }
    if (type === 'custom' && endDate) {
        queryParams.push(`endDate=${endDate.getTime()}`);
    }
    if (timezone) {
        queryParams.push(`timezone=${encodeURIComponent(timezone)}`);
    }

    // Append query parameters to the URL if any exist
    if (queryParams.length > 0) {
        url += `?${queryParams.join('&')}`;
    }

    return await apiGet(url);
};
