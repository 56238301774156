import { CButton, CCol } from '@coreui/react';
import React from 'react';

export type ButtonProps = {
    type: string;
    children: React.ReactNode;
};
const Button = ({ type, children }: ButtonProps) => {
    return (
        <CCol sm="2" md="1" className="mb-3 mb-xl-0">
            <CButton block color={type}>
                {children}
            </CButton>
        </CCol>
    );
};

export default Button;
